import { useEffect, useState } from "react";
import { GiChestnutLeaf, GiFarmTractor, GiFarmer } from "react-icons/gi";
import { FaUserCircle } from "react-icons/fa";
import { REGISTRY } from "../configs/Utils"
import './Widgets.scss';

const AcessLevel = (props: {levels: any, onSelectLevel: any}) => {
    const [selectedLevel, setSelection] = useState<any>(props.levels[0]);

    const _selectLevel = (l: any) => {
        setSelection(l);
        props.onSelectLevel(l);
    }
    useEffect(() => {
        _selectLevel(props.levels[0]);
    }, [])
    return (
        <>
        {
            props.levels.map((l: any) => (
                <div className={"level " + (selectedLevel === l && 'selectedLevel')} onClick={() => _selectLevel(l)}><IconGetter option={l}/>{l}</div>
            ))
        }
        </>
    )
}

const IconGetter = (props: {option: any}) => {
    return (
        <div className="icon">
            { props.option === REGISTRY.CROP_STACK && <GiChestnutLeaf/>}
            { props.option === REGISTRY.FARMER_STACK && <FaUserCircle/>}
            { props.option === REGISTRY.FARM_STACK && <GiFarmTractor/>}
        </div>
    )
}

export {AcessLevel};