import { Accordion, AccordionDetails, AccordionSummary, Button, Card, CardActions, CardContent, CardHeader, Divider, Grid, List, ListItem, ListItemText, Paper, Typography } from '@mui/material'
import React, { useState } from 'react'
import './DataViewComponent.scss'
import PublicIcon from '@mui/icons-material/Public';
import { dividerStyle, typographyData, typographyHeader, typographySubHeader } from '../styles/styles';
import VariantAccordion from './VariantAccordion';
import ModalView from '../claims/ModalView';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const DataViewComponent = (props: { code: any, onClick: (batch: any) => void, batches: [], onSelect: any }) => {
  let profile = props.code?.profile;
  const [open, setopen] = useState<boolean>(false)

  const openInNewTab = (url: string): void => {
    const newWindow = window.open(url, '_blank', 'noopener,noreferrer')
    if (newWindow) newWindow.opener = null
  }

  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };

  return (
    <div className='regionFormWrapper'>
      <Card variant="elevation" style={{ background: 'AppWorkspace', fillOpacity: 3 }}>
        <CardHeader
          avatar={
            <PublicIcon color={'action'} />
          }
          title="INDIA"
          titleTypographyProps={typographyHeader}
        />
        <Divider style={{ borderWidth: 3, borderColor: 'grey', }} variant='middle' />
        <CardContent>

          <Grid container spacing={1}>
            <Grid item xs={4} >
              <Typography style={typographyHeader}>
                {`ORCHARD ID`}
              </Typography>
            </Grid>
            <Grid item xs={4}>
              <Typography style={typographyData}>
                {` ${profile.orchardId}`}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={dividerStyle} />

          <Paper style={{ maxHeight: 350, overflow: 'auto' }}>
            <List style={{ padding: 5, }}>
              <Grid container spacing={2}>
                <Grid item spacing={2}>
                  <Typography style={typographySubHeader} >
                    TRESS
                  </Typography>
                  <Typography style={typographyData}>
                    {`${profile.trees}`}
                  </Typography>
                </Grid>

                <Grid item spacing={2}>
                  <Typography style={typographySubHeader} >
                    AREA (in Acre)
                  </Typography>
                  <Typography style={typographyData}>
                    {`${profile.area}`}
                  </Typography>
                </Grid>

                <Grid item spacing={2}>
                  <Typography style={typographySubHeader} >
                    EY (in Kg)
                  </Typography>
                  <Typography style={typographyData}>
                    {`${profile.ey}`}
                  </Typography>
                </Grid>
              </Grid>
              <Divider style={dividerStyle} />

              <Grid container spacing={1}>
                <Grid item xs={4}>
                  <Typography style={typographySubHeader} >
                    VARIANT
                  </Typography>
                </Grid>
              </Grid>
              <VariantAccordion variant={profile.variant} />

              <Divider style={dividerStyle} />

             
              <Button size="small" variant='text' style={{ color: '#FF8C00' }} onClick={() => setopen(true)}>
                More Info...
              </Button>

            </List>
          </Paper>

          <Divider style={dividerStyle} />
          <Grid container>
            <Grid item xs={4}>
              <Typography style={typographySubHeader} >
                {`ADDRESS`}
              </Typography>
            </Grid>
            <Grid item xs={8}>
              <Typography style={typographyData}>
                {` ${props.code.name}`}
              </Typography>
            </Grid>
          </Grid>
          <Divider style={dividerStyle} />
          <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                  expandIcon={<ExpandMoreIcon />}
                  aria-controls="panel1bh-content"
                  id="panel1bh-header"
                >
                  <Typography style={typographySubHeader} >BATCHES</Typography>
                </AccordionSummary>
                {
                  props.batches.map((batch: any) => {
                    return <AccordionDetails>
                      <Typography style={typographyData} onClick={() => props.onClick(batch)} className={'batch ' + (batch.id == props.onSelect?.id ? 'selected' : '')}>
                        {batch.name}
                      </Typography>

                    </AccordionDetails>
                  })}
              </Accordion>


        </CardContent>
        <Divider style={{ borderWidth: 2, borderColor: 'grey', marginBottom: 10 }} variant='middle' />
        <CardActions style={{ justifyContent: 'center' }}>
          <Button size="small" variant='outlined' style={{ borderColor: '#FF8C00', color: '#FF8C00' }} onClick={() => openInNewTab(props.code.profile.url)}>
            View Info on BlockChain
          </Button>
        </CardActions>
      </Card>
      {open && <ModalView open={open} handleClose={() => { setopen(false) }} claims={profile.claims} />}
    </div>
  )
}

export default DataViewComponent
