
export const ROLES :any = {
    'FEUDATORY_OWNER': 'government',
    'BANK': 'bank',
    // 'PRIMARY_OWNER': 'farmer',
    'THIRD_PARTY': 'supplier'
}
export const REGISTRY = {
    'FARM_STACK': 'farm',
    'FARMER_STACK': 'farmer',
    'CROP_STACK': 'crop'
}
export const role_registry_mapper:any = {
    'BANK' : [REGISTRY.FARMER_STACK, REGISTRY.FARM_STACK],
    // 'PRIMARY_OWNER' : [REGISTRY.FARMER_STACK, REGISTRY.FARM_STACK, REGISTRY.CROP_STACK],
    'FEUDATORY_OWNER' : [REGISTRY.FARMER_STACK, REGISTRY.FARM_STACK, REGISTRY.CROP_STACK],
    'THIRD_PARTY' : [REGISTRY.FARM_STACK,REGISTRY.CROP_STACK ]
}
export const registeredUsers = [
    {username:'gov',password:'password',role:ROLES.FEUDATORY_OWNER},
    {username:'bank',password:'password',role:ROLES.BANK},
    {username:'supplier',password:'password',role:ROLES.THIRD_PARTY},
]
export const DataLabels: any = {
    'aadhar_num': 'Aadhar No.',
    'address': 'Address',
    'age': 'Age',
    'farmer_id': 'Farmer ID',
    'father_name': "Father's Name",
    'gender': 'Gender',
    'mobile_num': 'Mobile No.',
    'name': 'Name',
    'crops_grown': 'Crops Grown',
    'geo_coordinates': 'Geo Coordinates',
    'irrigation_src': 'Irrigation Source',
    'land_use': 'Land usage',
    'landholding_size': 'Land held(hectares)',
    'plot_id': 'Plot ID',
    'soil_card_num': 'Soil Health Card ID',
    'soil_type': 'Soil Type',
    'survey_num': 'Survey #',
    'crop_id': 'Crop ID',
    'crop_type': 'Crop Type',
    'expected_harvest_date': 'Exp. Harvest Time',
    'intercropping': 'Crop Rotation',
    'irrigation_scheme': 'Irrigation Scheme',
    'sowing_date': 'Sowing Date',
    'variety': 'Variety',
}

export const getRegistrySignature = (opt: any) => {
    return opt === REGISTRY.FARMER_STACK ? 'farmer' : opt === REGISTRY.FARM_STACK ? 'farm' : 'crop';
}

export const _getDecimalPlaceValue = (val: any, dec?: number) => {
    dec = dec ? dec : 1000;
    return Math.round(val * dec)/dec;
}

export const _extractHeaders = (obj: any) => {
    let headers: any = [];
    Object.keys(obj).forEach((o: any) => {
        let hObj = { columnname: o, label: DataLabels[o] }
        headers.push(hObj);
    })
    return headers;
}

export const getDetailsByEntityId = (ent: any, id: any, data: any) => {
    let entityData = _getEntitySpecificData(ent, id, data[ent]);
    switch(ent) {
        case REGISTRY.FARMER_STACK:
            entityData.forEach((entData: any) => {
                let cropData = _getEntitySpecificData(REGISTRY.FARMER_STACK, entData.farmer_id, data[REGISTRY.CROP_STACK]);
                cropData.forEach((crData: any) => {
                    crData.farms = _getEntitySpecificData(REGISTRY.FARM_STACK, crData.plot_id, data[REGISTRY.FARM_STACK]);
                });
                entData.crops = cropData;
            });
            return entityData;
        case REGISTRY.CROP_STACK:
            entityData.forEach((entData: any) => {
                if (entData.plot_id) entData.farms = _getEntitySpecificData(REGISTRY.FARM_STACK, entData.plot_id, data[REGISTRY.FARM_STACK]);
                if (entData.farmer_id) entData.farmers = _getEntitySpecificData(REGISTRY.FARMER_STACK, entData.farmer_id, data[REGISTRY.FARMER_STACK]);
            });
            return entityData;
        case REGISTRY.FARM_STACK:
            entityData.forEach((entData: any) => {
                if (entData.crop_id) entData.crops = _getEntitySpecificData(REGISTRY.CROP_STACK, entData.crop_id, data[REGISTRY.CROP_STACK]);
                if (entData.farmer_id) entData.farmers = _getEntitySpecificData(REGISTRY.FARMER_STACK, entData.farmer_id, data[REGISTRY.FARMER_STACK]);
            });
            return entityData;
    }
}

const _getEntitySpecificData = (ent: any, id: any, data: any) => {
    let ent_id = ent === REGISTRY.FARMER_STACK ? 'farmer_id' : ent === REGISTRY.FARM_STACK ? 'plot_id' : 'crop_id';
    return data.filter((d: any) => d[ent_id] === id);
}