import React, { useEffect, useState } from 'react';
import './MapTalks.scss';
import * as maptalks from 'maptalks';
import DataViewComponent from './profile/DataViewComponent';

const MapTalks = () => {
  const [selectedFarm, setSelectedFarm] = React.useState<any>(null);
  const [selectedBatch, setSelectedBatch] = React.useState<any>(null);
  // const farms = [
  //   { id: "f1", name: 'Farm 1', location: [78.3341674, 29.4743746], batchs: [{ id: 1, name: 'Batch 1' }, { id: 2, name: 'Batch 2' }], alert: true },
  //   { id: "f2", name: 'Farm 2', location: [77.7036174, 29.5379712], batchs: [{ id: 1, name: 'Batch 1' }, { id: 3, name: 'Batch 3' }, { id: 5, name: 'Batch 5' }]},
  //   { id: "f3", name: 'Farm 3', location: [79.4304, 28.3670], batchs: [{ id: 4, name: 'Batch 4' }, { id: 5, name: 'Batch 5' }] }
  // ];
  const farms = [
    {
      id: "f1", name: 'Amrauli, Uttar Pradesh', location: [ 80.779112, 26.895446,],
      batchs: [{ id: 1, name: 'Batch 1' }, { id: 2, name: 'Batch 2' }], alert: true,
      profile: {
        orchardId: 'UPMF001',
        trees: 110,
        area: 2.5,
        ey: 15400,
        // pestAttack: ['Mango Weevils', 'Mango Hopper', 'Borers', 'Scales', 'Fruit flies'],
        variant: [
          {
            name: 'Dasheri', trees: 32, ey: 6000,
          },
          {
            name: 'Langra', trees: 23, ey: 3000,
          },
          {
            name: 'Chausa', trees: 28, ey: 4000,
          },
          {
            name: 'Safeda', trees: 27, ey: 2400,
          },
        ],
        claims: {
          pest: [{
            name: 'Pest mgt feb23',
            pest_names: ['Mango Hopper', 'Borers'],
            spray_month: 'February',
            remedial: 'Chemical Application',
            chemicals_used: ['Quinolphos 25 % EC'],
            spray_no: 3,
            chemical_qty: '3 lit',
            chemical_price_per_lt: '350',
            tankers_no: 3,
            chemical_total: 9,
            chemical_cost: 3150,
            tanker_cost: 1200,
            total_cost: 4350
          },
          {
            name: 'Pest mgt dec23',
            pest_names: ['Mango Hopper', 'Borers'],
            spray_month: 'December',
            remedial: 'Chemical Application',
            chemicals_used: ['Lambda Cyhalothrin 5% EC'],
            spray_no: 2,
            chemical_qty: '1.5 lit',
            chemical_price_per_lt: 400,
            tankers_no: 3,
            chemical_total: 4.5,
            chemical_cost: 1800,
            tanker_cost: 1200,
            total_cost: 3000
          },
          {
            name: 'Pest mgt june23',
            pest_names: ['Mango Hopper', 'Borers'],
            spray_month: 'June',
            remedial: 'Chemical Application',
            chemicals_used: ['Cypermethrin 5% EL + Chloropyriphos 50%'],
            spray_no: 1,
            chemical_qty: '2 lit',
            chemical_price_per_lt: 550,
            tankers_no: 3,
            chemical_total: 6,
            chemical_cost: 330,
            tanker_cost: 1200,
            total_cost: 4500
          },
          {
            name: 'Pest mgt mar23',
            pest_names: ['Mango Hopper', 'Borers'],
            spray_month: 'March',
            remedial: 'Chemical Application',
            chemicals_used: ['Imidacloprid 70 % Dry'],
            spray_no: 5,
            chemical_qty: '150gm',
            chemical_price_per_lt: 3300,
            tankers_no: 3,
            chemical_total: 500,
            chemical_cost: 1650,
            tanker_cost: 1200,
            total_cost: 2850
          },
          ],
          irrigation: [
            {
              name: 'Irrigation april23',
              technique: 'Drip irrigation',
              frequency: 2,
              month: 'April',
              cost_per_hr: 100,
              duration_hr: 25,
              cost: 2500,
              labour_cost: 200,
            },
            {
              name: 'Irrigation dec23',
              technique: 'Drip irrigation',
              frequency: 1,
              month: 'Dec',
              cost_per_hr: 100,
              duration_hr: 25,
              cost: 2500,
              labour_cost: 200,
            },
          ],
          m_f: [{
            name: 'M&f mgt july23',
            applied: ['FYM', 'NPK'],
            no_of_application: 1,
            month: 'July',
            cost_per_kg_per_trolly: '1500,  24',
            qty_per_kg_per_trolly: '4, 400',
            mf_cost: '6000,9600',
            labour_cost: 400,
          }],
          ap: [],
          pruning: [],
        },
        url: 'https://www.maya.explorer.borlaug.network/identity/did:bws:369d79431a86556d9286f77382cad44c3592d9b247a3cff4f2f1ab7a8f820383/',
      },
    },
    {
      id: "f2", name: 'Nabi Panah, Uttar Pradesh', location: [ 80.7270, 26.9794,], batchs: [{ id: 1, name: 'Batch 1' }, { id: 3, name: 'Batch 3' }, { id: 5, name: 'Batch 5' }],
      profile: {
        orchardId: 'UPMF002',
        trees: 175,
        area: 5.625,
        ey: 24967.50,
        pestAttack: [],
        variant: [
          {
            name: 'Dasheri', trees: 55, ey: 6562.5,
          },
          {
            name: 'Langra', trees: 34, ey: 4375,
          },
          {
            name: 'Chausa', trees: 43, ey: 8750,
          },
          {
            name: 'Safeda', trees: 43, ey: 5280,
          },
        ], claims: {
          pest: [],
          irrigation: [
            {
              name: 'Irrigation may23',
              technique: 'Drip irrigation',
              frequency: 2,
              month: 'May',
              cost_per_hr: 120,
              duration_hr: 50,
              cost: 6000,
              labour_cost: 500,
            },
            {
              name: 'Irrigation april23',
              technique: 'Drip irrigation',
              frequency: 1,
              month: 'April',
              cost_per_hr: 120,
              duration_hr: 60,
              cost: 7200,
              labour_cost: 500,
            },
          ],
          m_f: [{
            name: 'M&f mgt july23',
            applied: ['FYM', 'DAP'],
            no_of_application: 1,
            month: 'July',
            cost_per_kg_per_trolly: '1500,  28',
            qty_per_kg_per_trolly: '15, 250',
            mf_cost: '22500,7000',
            labour_cost: 500,

          }],
          ap: [],
          pruning: [],
        },
        url: 'https://www.maya.explorer.borlaug.network/identity/did:bws:b943239a6959b00222c9a90a008a35ac3d6ddbda3275c13d8ce3fccadd35d88d/',
      },
    },
    {
      id: "f3", name: 'Dhakwa, Uttar Pradesh', location: [81.3248,27.2378, ], batchs: [{ id: 4, name: 'Batch 4' }, { id: 5, name: 'Batch 5' }],
      profile: {
        orchardId: 'UPMF004',
        trees: 110,
        area: 2.5,
        ey: 48000,
        pestAttack: [],
        variant: [
          {
            name: 'Dasheri', trees: 110, ey: 48000,
          },
        ],
        claims: {
          pest: [],
          irrigation: [
            {
              name: 'Irrigation may23',
              technique: 'Drip irrigation',
              frequency: 3,
              month: 'May',
              cost_per_hr: 150,
              duration_hr: 15,
              cost: 2250,
              labour_cost: 0,
            },
            {
              name: 'Irrigation april23',
              technique: 'Drip irrigation',
              frequency: 2,
              month: 'April',
              cost_per_hr: 150,
              duration_hr: 15,
              cost: 2250,
              labour_cost: 0,
            }, {
              name: 'Irrigation dec23',
              technique: 'Drip irrigation',
              frequency: 1,
              month: 'Dec',
              cost_per_hr: 150,
              duration_hr: 15,
              cost: 2250,
              labour_cost: 0,
            },
          ],
          m_f: [],
          ap: [],
          pruning: [],
        },
        url: 'https://www.maya.explorer.borlaug.network/identity/did:bws:2f1c44f099dd7900d61a3d8b361340b987f185fa1692fe22dc543dd404b4c673/',
      },
    }
  ];
  const collectionCenters = [
    { id: "c1", name: 'Collection Center 1', location: [80.9462, 26.8467] },
    { id: "c2", name: 'Collection Center 2', location: [80.3319, 26.4499] },
  ];
  const ports = [{ id: "p1", name: 'Port', location: [82.1458, 26.7730] }];

  const batchesPath = [
    {
      id: 1, path: [
        { from: { id: "f1", type: "farm" }, to: { id: "c1", type: "collectionCenter" } },
        { from: { id: "f2", type: "farm" }, to: { id: "c1", type: "collectionCenter" } },
        { from: { id: "c1", type: "collectionCenter" }, to: { id: "p1", type: "port" } }]
    },
    {
      id: 2, path: [
        { from: { id: "f1", type: "farm" }, to: { id: "c2", type: "collectionCenter" } },
        { from: { id: "c2", type: "collectionCenter" }, to: { id: "p1", type: "port" } }]
    },
    {
      id: 3, path: [
        { from: { id: "f2", type: "farm" }, to: { id: "c1", type: "collectionCenter" } },
        { from: { id: "c1", type: "collectionCenter" }, to: { id: "p1", type: "port" } }]
    },
    {
      id: 4, path: [
        { from: { id: "f3", type: "farm" }, to: { id: "c2", type: "collectionCenter" } },
        { from: { id: "c2", type: "collectionCenter" }, to: { id: "p1", type: "port" } }]
    },
    {
      id: 5, path: [
        { from: { id: "f2", type: "farm" }, to: { id: "c1", type: "collectionCenter" } },
        { from: { id: "f3", type: "farm" }, to: { id: "c1", type: "collectionCenter" } },
        { from: { id: "c1", type: "collectionCenter" }, to: { id: "p1", type: "port" } }]
    },
  ]

  let map: maptalks.Map;
  const [layer, setLayer] = React.useState<maptalks.VectorLayer>();

  useEffect(() => {
    initMap();
  }, []);

  useEffect(() => {
    addFarmMarkers();
  }, [layer]);

  const addFarmMarkers = () => {
    farms.forEach(farm => {
      const marker = getMarkerByFarm(farm);
      addMarker(marker);
      if (farm.alert) {
        addAlertMarker(marker);
      }
    });
  }
  const addAlertMarker = (marker: maptalks.Marker) => {
    marker.animate({
      'symbol': [
        {
          'markerType': 'ellipse',
          'markerFill': 'rgb(255,0,0)',
          'markerFillOpacity': 0.8,
          'markerLineColor': '#fff',
          'markerLineWidth': 1,
          'markerWidth': 50,
          'markerHeight': 50,
          'markerDx': 0,
          'markerDy': -20
        },
        marker.getSymbol()
      ]
    }, { repeat: true });
  }

  const getFarmAndDo = (e: any, callback: any) => {
    const properties = e.target.getProperties();
    const farm = farms.find(farm => farm.id === properties.id);
    if (farm) {
      callback(farm);
    }
  }

  const onFarmClick = (farm: any) => {
    console.log(farm);
    setSelectedFarm(farm);
  }

  const onFarmDblClick = (farm: any) => {
    console.log(farm);
  }

  const getMarkerByFarm = (farm: any): maptalks.Marker => {
    const marker = new maptalks.Marker(farm.location, {
      id: farm.id,
      properties: {
        'id': farm.id,
        'name': farm.name,
        'type': 'farm'
      },
      symbol: [
        {
          'markerType': 'ellipse',
          'markerFill': 'rgb(255,0,0)',
          'markerFillOpacity': 0.8,
          'markerLineColor': '#fff',
          'markerLineWidth': 1,
          'markerWidth': 40,
          'markerHeight': 40,
          'markerDx': 0,
          'markerDy': -20
        },
        {
          'markerFile': '/images/map_images/farm.png',
          'markerWidth': 40,
          'markerHeight': 40
        },
        {
          'textFaceName': 'sans-serif',
          'textName': farm.name,
          'textSize': 14,
          'textDy': 10
        },

      ]
    });
    marker.on('click', (e) => {
      getFarmAndDo(e, onFarmClick);
    })
    marker.on('dblclick', (e) => {
      getFarmAndDo(e, onFarmDblClick);
    })
    return marker;
  }

  const getMarkerByCollectionCenter = (collectionCenter: any): maptalks.Marker => {
    return new maptalks.Marker(collectionCenter.location, {
      id: collectionCenter.id,
      properties: {
        'id': collectionCenter.id,
        'name': collectionCenter.name,
        'type': 'collectionCenter'
      },
      symbol: {
        'markerFile': '/images/map_images/cc.png',
        'markerWidth': 40,
        'markerHeight': 40
      }
    });
  }

  const getMarkerByPort = (port: any): maptalks.Marker => {
    return new maptalks.Marker(port.location, {
      id: port.id,
      properties: {
        'id': port.id,
        'name': port.name,
        'type': "port"
      },
      symbol: {
        'markerFile': '/images/map_images/port.png',
        'markerWidth': 40,
        'markerHeight': 40
      }
    });
  }

  const getMarkerById = (id: string, type: string): (maptalks.Marker | undefined) => {
    switch (type) {
      case "farm":
        const farm = farms.find(farm => farm.id === id);
        if (farm) {
          return getMarkerByFarm(farm);
        }
        break;
      case "collectionCenter":
        const collectionCenter = collectionCenters.find(collectionCenter => collectionCenter.id === id);
        if (collectionCenter) {
          return getMarkerByCollectionCenter(collectionCenter);
        }
        break;
      case "port":
        const port = ports.find(port => port.id === id);
        if (port) {
          return getMarkerByPort(port);
        }
        break;
    }
  }

  const getLine = (fromMarker: maptalks.Marker, toMarker: maptalks.Marker): maptalks.LineString | undefined => {
    const fromLocation = fromMarker.getCoordinates();
    const toLocation = toMarker.getCoordinates();
    if (fromLocation && toLocation) {
      /* return new maptalks.LineString([fromLocation, toLocation], {
        properties: {
          'line-color': '#000',
          'line-width': 5
        }
      }); */
      return new maptalks.ArcConnectorLine(fromMarker, toMarker, {
        id: `${fromMarker.getId()}-${toMarker.getId()}`,
        arcDegree: 90,
        showOn: 'always',
        arrowStyle: 'classic',
        arrowPlacement: 'vertex-last',
        symbol: {
          lineColor: '#34495e',
          lineWidth: 1,
        }
      });
    }
  }
  const addMarker = (marker: maptalks.Marker | undefined) => {
    if (marker && !layer?.getGeometryById(marker.getId())) {
      layer?.addGeometry(marker);
    }
  }

  const addLine = (line: maptalks.LineString | undefined) => {
    if (line && !layer?.getGeometryById(line.getId())) {
      layer?.addGeometry(line);
    }
  }

  const onBatchClick = (batch: any) => {
    if (selectedBatch) {
      clearConnetionsForBatch(selectedBatch.id);
    }
    setSelectedBatch(batch);
    const batchPath = batchesPath.find(batchPath => batchPath.id === batch.id);
    if (batchPath) {
      batchPath.path.forEach((path: any, index: number) => {
        const frommarker = getMarkerById(path.from.id, path.from.type);
        addMarker(frommarker);
        const tomarker = getMarkerById(path.to.id, path.to.type);
        addMarker(tomarker);

        if (frommarker && tomarker) {
          const line = getLine(frommarker, tomarker);
          addLine(line);
        }
      })
    }
  }

  const clearConnetionsForBatch = (batchId: number) => {
    const batchPath = batchesPath.find(batchPath => batchPath.id === batchId);
    if (batchPath) {
      let geometriesToRemove: any[] = [];
      batchPath.path.forEach((path: any, index: number) => {

        const frommarker = layer?.getGeometryById(path.from.id);
        if (frommarker) {
          let prop: any = frommarker.getProperties();
          if (prop['type'] != 'farm') {
            geometriesToRemove.unshift(frommarker);
          }
        }
        const tomarker = layer?.getGeometryById(path.to.id);
        if (tomarker) {
          geometriesToRemove.unshift(tomarker);
        }
        if (frommarker && tomarker) {
          const line = layer?.getGeometryById(`${frommarker.getId()}-${tomarker.getId()}`);
          if (line) {
            geometriesToRemove.unshift(line);
          }
        }

      })
      geometriesToRemove.forEach(geometry => {
        layer?.removeGeometry(geometry);
      })
    }
  }

  const initMap = () => {
    if (!map) {
      map = new maptalks.Map('map', {
        center: [80.9462, 26.8467],
        zoom: 6.8,
        baseLayer: new maptalks.TileLayer('base', {
          urlTemplate: 'http://mt1.google.com/vt/lyrs=m&x={x}&y={y}&z={z}',
          subdomains: ["a", "b", "c", "d"],
          attribution: '&copy; <a href="http://osm.org">OpenStreetMap</a> contributors, &copy; <a href="https://carto.com/">CARTO</a>'
        })
      });

      let l = new maptalks.VectorLayer('vector').addTo(map);
      setLayer(l);
    }
  }

  return (
    <div>
      <div id="map" className="container"></div>
      {
        selectedFarm && 
        <>
        <DataViewComponent code={selectedFarm} onClick={onBatchClick} batches={selectedFarm.batchs} onSelect = {selectedBatch}/>
        {/* <div className="details-wrapper">
          <div className="details">
            <div className='details-header'>{selectedFarm.name}</div>
            <div>Batches</div>
            <ul>
              {
                selectedFarm.batchs.map((batch: any) => {
                  return <li key={batch.id} onClick={() => onBatchClick(batch)} className={'batch ' + (batch.id == selectedBatch?.id ? 'selected' : '')}>{batch.name}</li>
                })
              }
            </ul>
          </div>
        </div> */}
        </>
      }
    </div>
  );
}

export default MapTalks;
