
const loaderWrap = {
    background: '#fffbfb9e',
    width: '100%',
    zIndex:'2'
};
const loader = {
    // margin: '400px auto'
};
const marginRight = {
    marginRight: '7px'
};
const blankPage = {
    display: 'flex',
    width: '100%',
    height: '100%',
    justifyContent: 'center',
    alignItems: 'center'
}
const blankPageImage = {
    width: '400px',
    margin: '0px auto'
}
const loadingMsg = {
    margin: '0px auto',
    color: '#263442',
    fontSize: '18px',
    letterSpacing: '2px'
}

export const Loader = () => {
    return (
        <div style={loaderWrap} className='maxHeight absolute d-flex justify-content-center align-items-center'>
            <div className="text-center">
                <img src={process.env.PUBLIC_URL+'/images/spinner.gif'} style={loader} alt='Loading...'/>
            </div>
            {/* <div style={loader}>Loading...</div> */}
        </div>
    )
}

export const BlankPageMsg = (props: any) => {
    return (
        <div style={blankPage}>
        <img src={process.env.PUBLIC_URL+"/assets/icons/home-new-user-illustration.png"} style={blankPageImage} alt="logo"/>
        </div>
    );
}
export const LoadingPage = (props: any) => {
    return (
        <div style={blankPage}>
        <div style={loadingMsg}>Fetching Model Details
            &nbsp;&nbsp;<img src={process.env.PUBLIC_URL+"/images/loading.gif"} alt="logo"/>
        </div>
        </div>
    );
}