import React, { Component, lazy, Suspense, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Link } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';

import Login from './containers/Login/Login';

import { Loader, LoadingPage } from './configs/UtilsFC';
import Dashboard from './containers/Dashboard/Dashboard';
import DetailComponent from './containers/Detail/Detail';
import { loaderSubject } from './services/helper.service';
import MapTalks from './components/MapTalks/MapTalks';
const Landing = lazy(() => import("./containers/Landing/Landing"));
const NotFoundPage = lazy(() => import("./components/NotFoundPage"));


const App = () => {
  const [isLoading, setIsLoading] = useState(false);
  let loader = <></>;
  if (isLoading) {
    loader = <Loader />;
  }

  React.useEffect(() => {
    let subscription = loaderSubject.subscribe((flag: boolean) => {
      setIsLoading(flag);
    })
    return () => {
      if (subscription) {
        subscription.unsubscribe();
      }
    };
  }, [])


  return (
    <>
      {loader}
      {/* {opendialog && <ConfirmationDialogComponent options={{ type: statusMsg.type,header: statusMsg.text, buttonList: [ACTION_CONSTANTS.CLOSE], action: dialogAction }} />} */}
      <Suspense fallback={<LoadingPage />}>
        <Router basename='/'>
          {/* <Router basename='/'> */}
          <Routes>
            <Route path='/' element={< Login />}></Route>
            <Route path='/landing' element={< Landing />}>
              <Route path='/landing/dashboard' element={< Dashboard />} />
              <Route path="/landing/:entity/:id" element={<DetailComponent />} />
              <Route path="/landing/mapview" element={<MapTalks />} />
              <Route path='*' element={< NotFoundPage />}></Route>
            </Route>
            <Route path='*' element={< NotFoundPage />}></Route>
          </Routes>
        </Router>
      </Suspense>
    </>
  );
}


export default App;
