import { Accordion, AccordionSummary, Typography, AccordionDetails, Grid, Divider } from '@mui/material';
import React from 'react'
import { typographySubHeader, typographyData } from '../styles/styles';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const MFClaims = (props: { mf: [] }) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <>
            {props.mf.map((element: any, i: number) => {
                let panel = 'panel' + (i + 1);
                return (
                    <Accordion expanded={expanded === panel} onChange={handleChange(panel)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography>{element.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        Manure & Fertilizer Applied
                                    </Typography>
                                </Grid>
                                <Grid item style={{ flexDirection: 'row', display: 'flex' }}>
                                    {element.applied.map((element: string) => (
                                        <>
                                            <Typography style={typographyData}>
                                                {element}
                                            </Typography>
                                            <Divider orientation='vertical' flexItem style={{ borderColor: 'grey', borderWidth: 1, marginLeft: 5, marginRight: 5 }} />
                                        </>
                                    )
                                    )}
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        No. of Application
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.no_of_application}
                                    </Typography>
                                </Grid>
                            </Grid>

                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        Month of Application
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.month}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        Cost /Kg/Trolly
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.cost_per_kg_per_trolly}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        Quantity/Kg/Trolly
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.qty_per_kg_per_trolly}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        M & F Cost
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.mf_cost}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        Labour Cost
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.labour_cost}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </>
    )
}

export default MFClaims