import { useEffect, useState } from "react";
import { IoCloseOutline } from "react-icons/io5";
import { AcessLevel } from "../../components/Widgets";
import { REGISTRY, _extractHeaders, role_registry_mapper, getRegistrySignature } from "../../configs/Utils";
import './Dashboard.scss';
import { useNavigate } from "react-router-dom";
import TableComponent from "../../components/Table/Table";
import { MasterAPI } from "../../services/MasterAPI";
import { SiGooglemaps } from "react-icons/si";

const Dashboard = () => {

    const [registries, setAccessRegistries] = useState<any[]>([]);
    const [selectedRegistry, setRegistry] = useState<any>();
    const [headers, setHeaders] = useState<any[]>([]);
    const [data, setData] = useState<any[]>([]);
    const [showNLQ, toggleNLQ] = useState<boolean>(true);
    const [queries, setQueries] = useState<any>([]);
    const [queryLoading, setQueryLoading] = useState<any>(false);
    const navigate = useNavigate();

    const _closeNLQ = () => {
        toggleNLQ(false);
    }
    const rowClicked = (row: any) => {
        let signature = getRegistrySignature(selectedRegistry);
        let id = selectedRegistry === REGISTRY.FARMER_STACK ? 'farmer_id' : selectedRegistry === REGISTRY.FARM_STACK ? 'plot_id' : 'crop_id';
        navigate(`/landing/${signature}/${row[id]}`);
    }
    const _extractData = (data: any) => {
        setData(data);
        let headers = _extractHeaders(data[0]);
        setHeaders(headers);
    }


    const _getData = (opt: string) => {
        let signature = getRegistrySignature(opt);
        MasterAPI.getAggregateData(signature).then((res: any) => {
            if (res.status) {
                if (opt == 'farm') {
                    let dummyRow = { ...res.payload[0] }
                    dummyRow.crops_grown = 'Mango';
                    dummyRow.farmer_id = "FRM000050"
                    res.payload.push(dummyRow)
                }
                _extractData(res.payload);
            }
        })
    }


    const _onSelectRegistry = (lv: any) => {
        setRegistry(lv);
        _getData(lv);
    }

    const onQuery = (e: any) => {
        if (e.key === 'Enter') {
            onQuerySubmit(e.target.value);
            e.target.value = null;
        }
    }
    const onQuerySubmit = (q: any) => {
        let reqBody;
        if (q.length > 0) {
            queries.push({ type: 'q', reply: q });
            reqBody = { user_query: q };
        }
        setQueryLoading(true);
        MasterAPI.submitQuery(reqBody).then((res: any) => {
            let qArr = [...queries];
            if (res.status) {
                qArr.push({ type: 'a', reply: res.message });
            } else {
                qArr.push({ type: 'a', reply: `Sorry! I couldn't find an answer. Please try again!` });
            }
            setQueries(qArr);
            setQueryLoading(false);
        })
    }

    useEffect(() => {
        setAccessRegistries(role_registry_mapper[localStorage.getItem('roleid') as string]);
    }, [])

    const onMapViewClick = () => {
        navigate(`/landing/mapview`);
    }
    return (
        <>
            {!showNLQ && <div className="maxHeight">
                {registries && registries.length > 0 && <div className="accessWrapper flex">
                    <AcessLevel levels={registries} onSelectLevel={_onSelectRegistry} />
                    <div className="mapview">
                        <SiGooglemaps onClick={onMapViewClick} />
                    </div>
                </div>
                }
                {headers.length > 0 && data.length > 0 && <div className="bodyWrapper"><TableComponent headerList={headers} tableBody={data} onRowClick={rowClicked} /></div>}
            </div>
            }

            {showNLQ && <div className="maxHeight flex nlqPane">
                <div className="flex vertical">
                    <div className="smartAssist">Smart Assistance <IoCloseOutline onClick={_closeNLQ} /></div>
                    <input type="text" placeholder="Type in a query..." className="queryInputStyle" onKeyDown={onQuery} />
                    {queryLoading && <div className="loadingMsg">loading response...</div>}

                    <div className="aichatBody">
                        {queries.map((q: any) => (
                            <div className={"flex " + (q.type != 'q' ? 'justify-content-end' : '')} >
                                <div className={"queryText " + (q.type === 'q' ? 'query' : 'reply')}>{q.reply}</div>
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            }
        </>
    )
}

export default Dashboard;