import { Accordion, AccordionDetails, AccordionSummary, Divider, Grid, List, ListItem, ListItemButton, ListItemIcon, ListItemText, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import React from 'react'
import { typographyData, typographySubHeader } from '../styles/styles'

const PestClaimView = (props: { data: [] }) => {
  const [expanded, setExpanded] = React.useState<string | false>(false);

  const handleChange =
    (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
      setExpanded(isExpanded ? panel : false);
    };
  return (
    <>
      {props.data.map((e: any, i: number) => {
        let panel = 'panel' + (i + 1);
        return (
          <Accordion expanded={expanded === panel} onChange={handleChange(panel)}>
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1bh-content"
              id="panel1bh-header"
            >
              <Typography style={typographyData} >
                {e.name}
              </Typography>
            </AccordionSummary>

            <AccordionDetails>
              <Grid container spacing={1}>
                <Grid item>
                  <Typography style={typographySubHeader} >
                    Name of Pest
                  </Typography>
                </Grid>
                <Grid item style={{ flexDirection: 'row', display: 'flex' }}>
                  {e.pest_names.map((element: string) => (
                    <>
                      <Typography style={typographyData}>
                        {element}
                      </Typography>
                      <Divider orientation='vertical' flexItem style={{ borderColor: 'grey', borderWidth: 1, marginLeft: 5, marginRight: 5 }} />
                    </>
                  )
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography style={typographySubHeader} >
                    Month of Spray
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography style={typographyData}>
                    {e.spray_month}
                  </Typography>
                </Grid>
              </Grid>


              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography style={typographySubHeader} >
                    Remedial Process
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography style={typographyData}>
                    {e.remedial}
                  </Typography>
                </Grid>
              </Grid>


              <Grid container spacing={1}>
                <Grid item>
                  <Typography style={typographySubHeader} >
                    Chemicals Used :
                  </Typography>
                </Grid>
                <Grid item style={{ flexDirection: 'row', display: 'flex' }}>
                  {e.chemicals_used.map((element: string) => (
                    <>
                      <Typography style={typographyData}>
                        {element}
                      </Typography>
                      <Divider orientation='vertical' flexItem style={{ borderColor: 'grey', borderWidth: 1, marginLeft: 5, marginRight: 5 }} />
                    </>
                  )
                  )}
                </Grid>
              </Grid>
              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography style={typographySubHeader} >
                    No. of Spray
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography style={typographyData}>
                    {e.spray_no}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography style={typographySubHeader} >
                    Quantity/Tanker
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography style={typographyData}>
                    {e.chemical_qty}
                  </Typography>
                </Grid>
              </Grid>


              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography style={typographySubHeader} >
                    Chemical price/Litre/kg
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography style={typographyData}>
                    {e.chemical_price_per_lt}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography style={typographySubHeader} >
                    No. of Tanker
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography style={typographyData}>
                    {e.tankers_no}
                  </Typography>
                </Grid>
              </Grid>


              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography style={typographySubHeader} >
                    Total Chemical/Litre/Kg
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography style={typographyData}>
                    {e.chemical_total}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography style={typographySubHeader} >
                    Chemical Cost (Rs.)
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography style={typographyData}>
                    {e.chemical_cost}
                  </Typography>
                </Grid>
              </Grid>

              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography style={typographySubHeader} >
                    Tanker Cost (Rs.)
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography style={typographyData}>
                    {e.tanker_cost}
                  </Typography>
                </Grid>
              </Grid>


              <Grid container spacing={2}>
                <Grid item xs={4}>
                  <Typography style={typographySubHeader} >
                    Total Cost (Rs.)
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography style={typographyData}>
                    {e.total_cost}
                  </Typography>
                </Grid>
              </Grid>


            </AccordionDetails>
          </Accordion>
        )
      })}
    </>
  )
}

export default PestClaimView