import React, { useEffect, useState } from 'react';
import './Login.scss';
import { useNavigate } from "react-router-dom";
import { ROLES, registeredUsers } from './../../configs/Utils'
const Login = () => {
  return (
    <div className="login-wrapper">
      <div className="login-card">
        <div className="header-wrapper">
          <div className='flex logoWrapper'>
            <img className="header-logo" src={process.env.PUBLIC_URL+"/images/uplogo.jpeg"} alt="UP Logo" />
            <img className="agristack-logo" src={process.env.PUBLIC_URL+"/images/agristack.svg"} alt="agristack Logo" />
          </div>
          {/* <div className="header-main">
            AGRI-STACK
          </div> */}
          <div className="header-sub">
            Welcome Back ! Sign in to continue.
          </div>
        </div>
        <LoginForm />
      </div>
    </div>
  )
}

export default Login;
const LoginForm = () => {
  const [selectedRole, setselectedRole] = useState("FEUDATORY_OWNER");
  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const [isRememberMeChecked, setIisRememberMeChecked] = useState(false);
  const navigate = useNavigate();

  const selectRole = (opt: any) => {
    localStorage.setItem('roleid', opt);
    setselectedRole(opt)
  }
  const onLoginClick = () => {
    let selectedUser: any
    registeredUsers.map((a: any) => {
      if (a.role === ROLES[selectedRole])
        selectedUser = a
    })
    if (username == selectedUser.username && password == selectedUser.password) {
      localStorage.setItem('user', JSON.stringify(selectedUser));
      navigate('/landing/dashboard');
    }
    else
      alert('Invalid User')
  }
  useEffect(() => {
    selectRole('FEUDATORY_OWNER')
}, [])
  return (
    <>
      <form className="login-form" id="login-form">
        <div className="input-wrapper">
          <div className='flex'>
            {
              Object.entries(ROLES).map(([key, value]) => (
                <>
                  {
                    <div className={(selectedRole == key ? 'selectedBadge ' : '') + ' roleBadge'} onClick={() => selectRole(key)}>{ROLES[key]}</div>
                  }
                </>
              ))
            }
          </div>
        </div>
        <div className="input-wrapper">
          <label htmlFor="username">Username</label>
          <input type="text" id="username" name="usernameField" placeholder="Enter username" onChange={(event) => { setUsername(event.target.value) }} />
        </div>
        <div className="input-wrapper">
          <label htmlFor="password">Password</label>
          <input type="password" id="password" name="passwordField" placeholder="Enter password" value={password} onChange={(event) => { setPassword(event.target.value) }} />
        </div>
        <div className="login-button-wrapper text-center">
          <div className="checkbox-wrapper">
            <input type="checkbox" className="me-1" id="rememberMe" checked={isRememberMeChecked} onChange={(e) => setIisRememberMeChecked(!isRememberMeChecked)} />
            <label htmlFor="rememberMe">Remember me</label>
          </div>
          <button type="button" className="primaryBtnStyle greenBtn" onClick={onLoginClick}>LOGIN</button>
        </div>
      </form>
    </>
  );
}