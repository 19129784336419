import { BehaviorSubject } from 'rxjs';

const loaderSubject = new BehaviorSubject(false);


const MessageService = {
    
    setLoading: (f: boolean) => {
        loaderSubject.next(f);
    }
}


export { MessageService, loaderSubject };