import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material';
import React from 'react'
import { typographySubHeader, typographyData } from '../styles/styles';

import ExpandMoreIcon from '@mui/icons-material/ExpandMore';

const IrrigationClaim = (props: { irr: [] }) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <>
            {props.irr.map((element: any, i: number) => {
                let panel = 'panel' + (i + 1);
                return (
                    <Accordion expanded={expanded === panel} onChange={handleChange(panel)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography>{element.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        Irrigation technique
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.technique}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        Irrigation frequency
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.frequency}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        Month
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.month}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        Cost/Hour
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.cost_per_hr}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        Duration/Hours
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.duration_hr}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        Cost
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.cost}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        Labour cost
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.labour_cost}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )
            })}
        </>
    )
}

export default IrrigationClaim