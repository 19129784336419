import React, { useState } from 'react';
import './Table.scss';

const TableComponent = (props:{headerList:any[],tableBody:any[],onRowClick:any}) => {
    return (
        <div className='gridOuterWrapper maxHeight'>
            <table className='tableWrapper'>
                <thead>
                    <tr className='tableHeader'>
                        {
                            props.headerList.map((header: any) => {
                                return ( <th>{header.label}</th> )
                            })
                        }
                    </tr>
                </thead>
                <tbody>
                    {
                        props.tableBody.map((a: any) => {
                            return <>
                                <tr onClick={() => props.onRowClick(a)}>
                                    {
                                         props.headerList.map((h: any,indx) => {
                                            return (
                                                <>
                                                    {a[props.headerList[indx].columnname] ? <td>{a[props.headerList[indx].columnname]}</td> : <td></td>}
                                                </>
                                            )
                                        })
                                    }
                                </tr>
                            </>
                        })
                    }
                </tbody>
            </table>
        </div>
    )
}
export default TableComponent