import { Accordion, AccordionDetails, AccordionSummary, Grid, Typography } from '@mui/material'
import React from 'react'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { typographyData, typographySubHeader } from '../styles/styles';

const VariantAccordion = (props: { variant: [] }) => {
    const [expanded, setExpanded] = React.useState<string | false>(false);

    const handleChange =
        (panel: string) => (event: React.SyntheticEvent, isExpanded: boolean) => {
            setExpanded(isExpanded ? panel : false);
        };

    return (
        <>
            {props.variant.map((element: any, i: number) => {
                let panel = 'panel' + (i + 1);
                return (
                    <Accordion expanded={expanded === panel} onChange={handleChange(panel)}>
                        <AccordionSummary
                            expandIcon={<ExpandMoreIcon />}
                            aria-controls="panel1bh-content"
                            id="panel1bh-header"
                        >
                            <Typography>{element.name}</Typography>
                        </AccordionSummary>
                        <AccordionDetails>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        EY (in Kg)
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.ey}
                                    </Typography>
                                </Grid>
                            </Grid>
                            <Grid container spacing={2}>
                                <Grid item xs={4}>
                                    <Typography style={typographySubHeader} >
                                        TREES
                                    </Typography>
                                </Grid>
                                <Grid item xs={8}>
                                    <Typography style={typographyData}>
                                        {element.trees}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </AccordionDetails>
                    </Accordion>
                )
            }
            )}
        </>
    )
}

export default VariantAccordion