import { createContext, useContext, useEffect, useState } from "react";
import './Detail.scss'
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { useParams } from 'react-router-dom'
import { MasterAPI } from "../../services/MasterAPI";
import { DataLabels, REGISTRY, ROLES, getDetailsByEntityId } from "../../configs/Utils";
import { FaUserCircle } from "react-icons/fa";
import { GiChestnutLeaf, GiFarmTractor } from "react-icons/gi";

let profileCtxt = createContext({} as any);

const DetailComponent = () => {
    const imageLists = [
        { id: 1, url: '/images/landImages/1.png' },
        { id: 2, url: '/images/landImages/2.jpg' },
        { id: 3, url: '/images/landImages/3.jpg' },
    ]
    const clickableFields = ['plot_id'];
    const [agristackData, setAllStack] = useState<any>(); 
    const [details, setDetails] = useState<any>([]); 
    const [masks, setMasks] = useState<any>([]); 
    const { entity, id } = useParams()

    const _getAllData = () => {
        let usertype = ROLES[localStorage.getItem('roleid') as string];
        MasterAPI.getAllData(usertype).then((res: any) => {
            if (res.status) {
                setAllStack(res.payload);
                setMasks(res.masked_list);
            }
        })
    }
    const _isContentMasked = (str: string) => {
        return masks.includes(str) ? true : false;
    }
    useEffect(() => {
        if (agristackData) {
            let d = getDetailsByEntityId(entity, id, agristackData);
            console.log(d);
            setDetails(d);
        }
    }, [agristackData])


    useEffect(() => {
        _getAllData();
    }, [])
    return (
        <profileCtxt.Provider value={{details, imageLists, clickableFields, _isContentMasked}}>
            <div className="DetailWrapper">
                <Row className="detailsRow">
                    <Col sm={8}>
                        <div className="InfoContainer">
                            {
                                entity === REGISTRY.FARMER_STACK && details.length>0 &&
                                <FarmerProfile/>
                            }
                            {
                                entity === REGISTRY.FARM_STACK && details.length>0 &&
                                <FarmProfile/>
                            }
                            {
                                entity === REGISTRY.CROP_STACK && details.length>0 &&
                                <CropProfile/>
                            }
                        </div>
                    </Col>
                    <Col sm={4}>
                        <ImagePanel/>
                    </Col>
                </Row>
            </div>
        </profileCtxt.Provider>
    )
}
export default DetailComponent;

const ImagePanel = () => {
    const { imageLists } = useContext(profileCtxt);
    return (
        <div className="ImagePanel">
            {
                imageLists.map((a: any) => {
                    return (
                        <img className="satImages" src={process.env.PUBLIC_URL+a.url} />
                    )
                })
            }
        </div>
    )
}


const FarmerProfile = () => {
    const { details } = useContext(profileCtxt);
    return (
        <div className="entityWidget">
                <h3><FaUserCircle className="headericon"/>{details[0].name}</h3>
                <div className="location">{details[0].address}</div>
                <ObjectWrapper header='FARMER DETAILS' data={details[0]}/>
        </div>
    )
}

const FarmProfile = () => {
    const { details } = useContext(profileCtxt);
    return (
        <div className="entityWidget">
                <h3><GiFarmTractor className="headericon"/>{details[0].plot_id}</h3>
                <div className="location">{details[0].soil_type}</div>
                <ObjectWrapper header='LAND DETAILS' data={details[0]}/>
        </div>
    )
}
const CropProfile = () => {
    const { details } = useContext(profileCtxt);
    return (
        <div className="entityWidget">
                <h3><GiChestnutLeaf className="headericon"/>{details[0].crop_type}</h3>
                <div className="location">{details[0].soil_type}</div>
                <ObjectWrapper header='CROP DETAILS' data={details[0]}/>
        </div>
    )
}






const ObjectWrapper = (props: {header: string, data: any}) => {
    return (
        <div className="objectSection">  
            <div className="objectSectionHeader">{props.header}</div>
            <hr className="underline" />
            { typeof props.data === 'object' && <ObjectRenderer data={props.data}/> }
        </div>
    )
}
const ObjectRenderer = (props: any) => {
    const { clickableFields, _isContentMasked } = useContext(profileCtxt);
    const [selectedCrop, setSelectedCrop] = useState<any>();
    const [selectedPlot, setSelectedPlot] = useState<any>();
    const [selectedFarmer, setSelectedFarmer] = useState<any>();

    const _onSelectCrop = (crop: any) => {
        setSelectedCrop(crop);
        _onSelectPlot(null);
    }

    const _isClickable = (key: string) => {
        return clickableFields.includes(key) ? true : false;
    }

    const _onSelectKey = (key:string, val: string, data: any) => {
        switch(key) {
            case 'plot_id':
                if (data.farms && data.farms.length>0) {
                    let pl = data.farms.filter((p: any) => p[key] === val)[0];
                    _onSelectPlot(pl);
                }
                break;
        }
    }
    const _onSelectPlot = (plot: any) => {
        setSelectedPlot(plot);
    }
    const _onSelectFarmer = (f: any) => {
        setSelectedFarmer(f);
    }

    useEffect(() => {
        if (props.data.crops?.length > 0) {
            _onSelectCrop(props.data.crops[0]);
        }
    }, [])



    return (
        <>
        {
            Object.entries(props.data).map(([key, value]) => (
                <>
                { typeof value === 'string' && 
                    <Row className="info">
                        <Col sm={3} >{DataLabels[key]}</Col>
                        <Col sm={4} className={"obj_label " + (_isContentMasked(value) ? 'maskedContent' : '') + (_isClickable(key) ? ' link' : '')} onClick={() => _onSelectKey(key, value, props.data)}>{value}</Col>
                    </Row>
                }
                </>
            ))
        }
        {
            props.data.crops && props.data.crops instanceof Array && 
            <div className="innerObjectSection">
                <div className="flex badgeWrap">
                    { props.data.crops.map((cr: any) => ( <div className={"entitybadge " + (cr.crop_id === selectedCrop?.crop_id ? 'selectedEntityBadge' : '')} onClick={() => _onSelectCrop(cr)}><GiChestnutLeaf className="entityIcon"/>{cr.crop_type}</div> )) }
                </div>
                { selectedCrop && <ObjectWrapper header='CROP DETAILS' data={selectedCrop}/> }
            </div>
        }
        {
            props.data.farmers && props.data.farmers instanceof Array && 
            <div className="innerObjectSection">
                <div className="flex badgeWrap">
                    { props.data.farmers.map((cr: any) => ( <div className={"entitybadge " + (cr.farmer_id === selectedFarmer?.farmer_id ? 'selectedEntityBadge' : '')} onClick={() => _onSelectFarmer(cr)}><FaUserCircle  className="entityIcon"/>{cr.name}</div> )) }
                </div>
                { selectedFarmer && <ObjectWrapper header='FARMER DETAILS' data={selectedFarmer}/> }
            </div>
        }
        {
            selectedPlot && <ObjectWrapper header='FARM LAND DETAILS' data={selectedPlot}/>
        }
        
        </>
    );
}

