import { Modal, Box, Typography, Tab, Tabs, tabsClasses } from '@mui/material'
import React from 'react'
import PestClaimView from './PestClaimView';
import { Height } from '@mui/icons-material';
import IrrigationClaim from './IrrigationClaim';
import MFClaims from './MFClaims';


export const claimTabs = [
    { name: 'pest', value: "Pest Management", },
    { name: 'irrigation', value: "Irrigation", },
    { name: 'm_f', value: 'Manure and Fertilizer Management', },
    { name: 'ap', value: 'Actual Production', },
    { name: 'pruning', value: 'Pruning' },
  ];
  
const ModalView = (props: { open: boolean, handleClose: () => void, claims: any }) => {
    const style = {
        position: 'absolute' as 'absolute',
        top: '50%',
        left: '50%',
        transform: 'translate(-50%, -50%)',
        width: 500,
        Height: 600,
        bgcolor: 'background.paper',
        border: '2px solid #fff',
        boxShadow: 24,
        p: 4,
    };
    const [value, setValue] = React.useState(0);

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };

    function a11yProps(index: number) {
        return {
            id: `simple-tab-${index}`,
            'aria-controls': `simple-tabpanel-${index}`,
        };
    }

    const renderTabData = () => {
        if (value === 0) {
            return <PestClaimView data={props.claims.pest} />
        }
        else <></>
    }

    return (
        <Modal
            open={props.open}
            onClose={props.handleClose}
            aria-labelledby="modal-modal-title"
            aria-describedby="modal-modal-description"
        >
            <Box sx={style}>
                <Typography id="modal-modal-title" variant="h6" component="h2">
                    Claims
                </Typography>
                <div id="modal-modal-description" style={{ marginTop: 2 }}>
                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                        <Tabs value={value} onChange={handleChange}
                            variant="scrollable"
                            scrollButtons
                            sx={{
                                [`& .${tabsClasses.scrollButtons}`]: {
                                    '&.Mui-disabled': { opacity: 0.3 },
                                },
                            }}
                        >
                            {claimTabs.map((e: any, i: number) =>
                                <Tab label={e.value} {...a11yProps(i)} />
                            )}
                        </Tabs>
                    </Box>

                    {(value === 0) && <PestClaimView data={props.claims.pest} />}

                    {(value === 1) && <IrrigationClaim irr={props.claims.irrigation} />}

                    {(value === 2 && <MFClaims mf={props.claims.m_f} />)}
                </div>
            </Box>
        </Modal>
    )
}

export default ModalView